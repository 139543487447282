

































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Application, Payout} from '@/models';
import {MerchantAPI} from '@/api';
import axios, {Canceler} from 'axios';

interface Filter {
  amount: number | null;
  status: string;
  application: string;
  createdAtStart: Date;
  createdAtEnd: Date;
}

@Component
export default class Payouts extends Vue {
  protected payouts: Payout[] = [];
  protected applications: Application[] = [];

  protected errors: string[] = [];
  protected total: number = 0;
  protected isLoading: boolean = false;
  protected downloading: boolean = false;
  protected sortField: string = 'id';
  protected sortOrder: string = 'desc';
  protected defaultSortOrder: string = 'desc';
  protected page: number = 1;
  protected perPage: number = 10;
  protected openedDetails: string[] = [];

  protected lastWeek(): Date {
    const lastWeek = new Date();
    lastWeek.setDate(new Date().getDate() - 7);

    return lastWeek;
  }

  protected emptyFilter(): Filter {
    return {
      amount: null,
      status: 'all',
      application: 'all',
      createdAtStart: this.lastWeek(),
      createdAtEnd: new Date(),
    };
  }

  protected defaultFilter(): Filter {
    const query = Object(this.$route.query);

    return {
      amount: query.amount && !isNaN(query.amount) ? Number(query.amount) : null,
      status: query.status && query.status !== 'null' ? query.status : 'all',
      application: query.application && query.application !== 'null' ? query.application : 'all',
      createdAtStart: query.created_at_start && query.created_at_start !== 'null'
          ? new Date(query.created_at_start) : this.lastWeek(),
      createdAtEnd: query.created_at_end && query.created_at_end !== 'null'
          ? new Date(query.created_at_end) : new Date(),
    };
  }

  protected filter: Filter = this.defaultFilter();
  private requests: Canceler[] = [];

  public mounted() {
    MerchantAPI
        .applications({'with_trashed': true, 'paginated': false})
        // .then((res) => this.applications = res.payload.filter((item: any) => item.payout).map((a: any) => new Application(a)))
        .then((res) => this.applications = res.payload.map((a: any) => new Application(a)))
        .catch(err => {
          this.errors.push(`"Applications" - ` + err.message);
          this.applications = [];
        });

    this.loadAsyncData();
  }

  private valueRegex(event: any, regex: string) {
    event = event ?? window.event;
    const re = new RegExp(regex);
    const clipboardData = event.clipboardData || window.clipboardData;
    let str: string;

    if (clipboardData !== undefined) {
      str = clipboardData.getData('Text');
    } else {
      str = String.fromCharCode(event.which ?? event.keyCode);
    }

    if (!re.test(str)) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  @Watch('filter', {immediate: false, deep: true})
  onFiltersChanged() {
    this.loadAsyncData();
  }

  private onPageChange(page: number) {
    this.page = page;
    this.loadAsyncData();
  }

  private onSort(field: string, order: string) {
    this.sortField = field;
    this.sortOrder = order;
    this.loadAsyncData();
  }

  private onClear() {
    this.filter = this.emptyFilter();
  }

  private loadAsyncData() {
    this.errors = [];
    this.isLoading = true;
    this.requests.map((cancel: Canceler) => cancel());
    const req = axios.CancelToken.source();
    this.requests.push(req.cancel);

    const filters = {
      amount: this.filter.amount ?? 'null',
      status: this.filter.status,
      application: this.filter.application,
      created_at_start: this.filter.createdAtStart,
      created_at_end: this.filter.createdAtEnd,
    };
    this.$router.replace({query: JSON.parse(JSON.stringify(filters))}).catch(err => err);

    MerchantAPI
        .payouts(req, {
          page: this.page,
          per_page: this.perPage,
          sort_field: this.sortField,
          sort_order: this.sortOrder,
          ...filters
        })
        .then((res: any) => {
          this.total = res.count;
          this.payouts = res.data.map((item: any) => new Payout(item));
        }).catch((err: any) => {
          this.payouts = [];
          this.total = 0;

          if (!axios.isCancel(err)) {
            this.errors.push(`"Payouts" - ` + err.message);
          }
        }).finally(() => {
          this.isLoading = false;
        });
  }

  private onDownload(type: string) {
    this.errors = [];
    this.downloading = true;

    MerchantAPI
        .payoutExport(type, {
          orderBy: this.sortField,
          ascending: this.sortOrder === 'desc' ? 0 : 1,
          // Filters
          amount: this.filter.amount,
          status: this.filter.status,
          application: this.filter.application,
          created_at_start: this.filter.createdAtStart,
          created_at_end: this.filter.createdAtEnd,
        }).then((res) => {
          const link = document.createElement('a');

          if (type === 'csv') {
            link.href = URL.createObjectURL(new Blob([res], {type: 'text/csv'}));
            link.setAttribute('download', 'payouts.csv');
            link.setAttribute('target', '_blank');
          }

          if (type === 'xlsx') {
            link.href = URL.createObjectURL(new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
            link.setAttribute('download', 'payouts.xlsx');
            link.setAttribute('target', '_blank');
          }

          document.body.appendChild(link); // Required for FF
          link.click();
          document.body.removeChild(link);
          link.remove();
        }).catch((err: any) => {
          this.errors.push(err.toString());
        }).finally(() => this.downloading = false);
  }
}
